/* Alerts
/* ------------------------- */
.alert--rounded {
	border-top-left-radius: 28px;
	border-bottom-left-radius: 28px;

	&::before {
		height: $alert-icon-width;
		top: 50%;
		transform: translateY(-50%);
		border-radius: 28px;
		box-shadow: inset 0 0 0 4px red;
	}
}

.alert-success {

	&::before {
		box-shadow: inset 0 0 0 4px $alert-success-icon-border;
	}
}

.alert-info {

	&::before {
		box-shadow: inset 0 0 0 4px $alert-info-icon-border;
	}
}

.alert-warning {

	&::before {
		box-shadow: inset 0 0 0 4px $alert-warning-icon-border;
	}
}

.alert-danger {

	&::before {
		box-shadow: inset 0 0 0 4px $alert-danger-icon-border;
	}
}
