/* Widget: Event Result */
.widget-game-result__title {
	font-style: normal;
}
.widget-game-result__subtitle {
	font-size: 10px;
	line-height: 1.2em;
}

.widget-game-result__subheader {

	.widget-game-result__subtitle {
		font-style: italic;
	}
}

.widget-game-result__header--alt {

	.widget-game-result__title {
		color: $body-font-color;

		@media (min-width: 992px) {
			font-size: 10px;
		}
	}

	.widget-game-result__date {

		@media (min-width: 992px) {
			font-size: 10px;
		}
	}
}


.widget-game-result__team {
	width: 32%;
}
	.widget-game-result__team-logo {
		height: 64px;

		@media (min-width: 992px) {
			height: 80px;
		}

		@media (min-width: 1200px) {
			height: 100px;
		}
	}

.widget-game-result__score-wrap {
	width: 36%;
}
	.widget-game-result__score {
		font-size: 24px;
		font-style: italic;

		@media (min-width: 768px) {
			font-size: 28px;
		}

		@media (min-width: 992px) {
			font-size: 32px;
		}

		@media (min-width: 1199px) {
			font-size: 36px;
		}
	}

	.widget-game-result__score-result--winner {

		&::before {
			display: none;
		}
	}

	.widget-game-result__score-label {
		font-size: 12px;
		padding-top: 10px;

		@media (min-width: 992px) {
			padding-top: 0;
		}
	}

	.widget-game-result__score-sublabel {
		display: block;
		font-size: 9px;
		font-family: $font-family-base;
		color: $body-font-color;
		font-weight: 400;
		text-transform: none;
	}


.widget-game-result__team-name {
	font-style: normal;

	@media (min-width: 992px) {
		font-size: 14px;
	}
}
.widget-game-result__team-desc {
	font-size: 10px;
	line-height: 1.2em;
	font-family: $font-family-base;
	text-transform: none;
	font-weight: 400;
}

/* Game Stats (additional) */
.widget-game-result__stats-circular-bars {
	display: flex;
	align-items: center;
	margin: 0;

	.widget-game-result__stats-circular-item {
		font-size: 12px;
		line-height: 1.2em;
		flex-grow: 1;
		text-align: center;

		.circular {
			margin: 0 auto 10px auto;
		}
	}
		.widget-game-result__stats-meta {
			text-align: center;
		}
			.widget-game-result__stats-value {
				display: block;
				color: $headings-color;
				font-family: $font-family-accent;
				font-weight: 700;
			}
			.widget-game-result__stats-label {
				font-size: 9px;
				line-height: 1.2em;
				display: block;
			}
}

// Circular Bars Layout: 4 cols
.widget-game-result__stats-circular-bars--cols-4 {
	flex-wrap: wrap;
	margin-bottom: -20px;
	overflow: hidden;

	.widget-game-result__stats-circular-item {
		flex-basis: 25%;
		max-width: 25%;
		margin-bottom: 20px;
	}
}

// Top Leaders
.widget-game-result__players {
	margin: 0;
}
	.widget-game-result__player {
		padding: 14px $card-horizontal-padding;
		display: flex;
		align-items: center;

		& + & {
			border-top: 1px solid $card-border-color;
		}
	}
		.widget-game-result__player-info {
			flex: 1;
			display: flex;
			align-items: center;
		}
			.widget-game-result__player-img,
			.widget-game-result__player-character-img {
				margin-right: 6px;
				border: 2px solid #189aff;
				border-radius: 3px;

				&.alc-img-object__img--hexagon {
					background-color: #189aff;
				}
			}

			.widget-game-result__player-img--color-alt,
			.widget-game-result__player-character-img--color-alt {
				border-color: #f21956;

				&.alc-img-object__img--hexagon {
					background-color: #f21956;
				}
			}

			.widget-game-result__player-img {

			}
			.widget-game-result__player-character-img {

			}
			.widget-game-result__player-meta {
				padding: 0 0 0 6px;
			}
				.widget-game-result__player-nickname {
					font-size: 12px;
					line-height: 1.2em;
					margin-bottom: 0;
				}
				.widget-game-result__player-character {
					font-size: 9px;
					line-height: 1.2em;
				}
		.widget-game-result__player-stats {
			flex-basis: 36%;
			text-align: right;

			// Progress Bars
			.progress-stats {
				margin-bottom: 0;
				text-align: left;

				.progress__label {
					font-size: 12px;
				}

				.progress {
					margin-top: 3px;
					margin-bottom: 0;
				}
			}
		}
			// Text
			.widget-game-result__player-stats-txt {
				display: inline-block;
				font-size: 11px;
				line-height: 1.2em;
				font-family: $font-family-accent;
				font-weight: 700;
				color: $headings-color;
			}


// Round by Round
.widget-game-result__rounds-table {
	margin-bottom: 0;

	thead {

		> tr {

			> th {
				text-align: center;
				font-style: normal;
			}
		}
	}

	tbody {

		> tr {

			> td {
				border: 1px solid $table-border-color;
				background-color: rgba(0,0,0,.1);
				text-align: center;
				vertical-align: middle;

				@media (min-width: 992px) {
					padding-left: $card-horizontal-padding / 2;
					padding-right: $card-horizontal-padding / 2;
				}

				&.widget-game-result__rounds-team {
					background-color: $color-dark-2;
					text-align: center;

					@media (min-width: 992px) {
						padding-left: $card-horizontal-padding;
						padding-right: $card-horizontal-padding;
					}
				}

				&:first-child {
					border-left-width: 0;
				}

				&:last-child {
					border-right-width: 0;
				}

				.df-icon {
					vertical-align: middle;
				}
			}

			&:last-child {

				> td {
					border-bottom-width: 0;
				}
			}
		}
	}

	th.widget-game-result__rounds-team {

		@media (min-width: 992px) {
			padding-left: $card-horizontal-padding;
			padding-right: $card-horizontal-padding;
		}
	}
}
