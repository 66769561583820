
.df-color-pallete__item {

	&.color-dark {
		background-color: $color-dark;
	}
	&.color-dark-lighten {
		background-color: $color-dark-lighten;
	}
	&.color-dark-2 {
		background-color: $color-dark-2;
	}
	&.color-dark-lighten-2 {
		background-color: $color-dark-lighten-2;
	}
	&.color-gray-2 {
		background-color: $color-gray-2;
	}
	&.color-gray-3 {
		background-color: $color-gray-3;
	}
}
