/* Widget: Tournament */
.alc-tourn-card {

	.alc-tourn-card__header {
		background-color: $card-header-bg;
		padding: $card-vertical-padding $card-horizontal-padding;
		border-bottom: 1px solid $card-border-color;
		display: flex;
		align-items: center;
	}

		.alc-tourn-card__icon {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			border: 4px solid $color-primary;
			text-align: center;
			margin-right: 10px;
			line-height: 32px;

			svg {
				vertical-align: middle;
			}
		}

		.alc-tourn-card__heading {
			flex: 1;
			align-items: center;
			font-size: 14px;
			line-height: 1.2em;
		}
			.alc-tourn-card__title {
				font-size: 14px;
				line-height: 1.2em;
				font-style: normal;

				@media (min-width: 992px) {
					font-size: 16px;
				}

				@media (min-width: 1200px) {
					font-size: 16px;
				}
			}

			.alc-tourn-card__date {
				font-size: 10px;
				line-height: 1.2em;
				font-style: normal;

				@media (min-width: 992px) {
					font-size: 10px;
				}
			}

			.alc-tourn-card__title + .alc-tourn-card__date {
				margin-top: 0 !important;
			}

		.alc-tourn-card__rank {
			font-family: $font-family-accent;
			text-transform: uppercase;
			font-weight: 700;
			font-size: 14px;
			line-height: 1.2em;
			text-align: center;
		}
			.alc-tourn-card__rank-value {
				font-size: 20px;
				line-height: 1em;
				color: $headings-color;
			}
			.alc-tourn-card__rank-label {
				font-size: 10px;
				line-height: 1.2em;
				padding-top: 4px;
			}

	.alc-tourn-card__content {

	}
		.alc-tourn-card__progress {
			margin-bottom: 0;

			.progress {
				margin-top: 10px;
				margin-bottom: 3px;
			}

			.progress__label,
			.progress__number {
				font-size: 10px;
				color: $headings-color;
			}
		}

	.alc-tourn-card__meta {
		display: flex;
		flex-wrap: wrap;
		border-style: solid;
		border-color: $card-border-color;
		border-width: 1px 0 0 1px;
		margin: -1px;
	}
		.alc-tourn-card__meta-item {
			padding: 20px;
			text-align: center;
			line-height: 1em;
			flex-grow: 1;
			border-style: solid;
			border-color: $card-border-color;
			border-width: 0 1px 1px 0;
		}
			.alc-tourn-card__meta-item-value {
				font-family: $font-family-accent;
				font-weight: 700;
				color: $headings-color;
				font-size: 16px;
				line-height: 1.2em;
				margin-bottom: .5em;
			}
			.alc-tourn-card__meta-item-label {
				font-size: 9px;
				line-height: 1.2em;
			}
	.alc-tourn-card__meta-item--highlight {

		.alc-tourn-card__meta-item-value {
			color: $color-primary;
		}
	}

	.alc-tourn-card__footer {
		background-color: $card-header-bg;
		padding-left: $card-horizontal-padding / 2;
		padding-right: $card-horizontal-padding / 2;
		text-align: center;
	}
	.alc-tourn-card__footer--desc {
		background-color: transparent;
		padding: 0;
	}

		.alc-tourn-card__footer-info {
			margin: 0;
			text-align: left;
		}
			.alc-tourn-card__footer-info-item {
				padding: $card-vertical-padding / 2 $card-horizontal-padding;
				display: flex;
			}
			.alc-tourn-card__footer-info-item + .alc-tourn-card__footer-info-item {
				border-top: 1px solid $card-border-color;
			}
				.alc-tourn-card__footer-info-label,
				.alc-tourn-card__footer-info-value {
					display: inline-block;
					font-size: 12px;
					line-height: 1.83em;
					font-family: $font-family-accent;
					color: $headings-color;
					font-weight: 700;
				}
				.alc-tourn-card__footer-info-value {
					margin-left: auto;
				}
			.alc-tourn-card__footer-info-item--excerpt {
				display: block;
				line-height: 22px;

				.alc-tourn-card__footer-info-label {
					display: inline-block;
				}

				.alc-tourn-card__footer-info-value {
					display: inline;
					color: $body-font-color;
					font-weight: 400;
					font-family: $font-family-base;
				}
			}

	// No inner paddings (Tournament Single)
	.card--no-paddings & {
		border: none;
		margin-bottom: 0;

		.posts__thumb {

			&,
			&:first-child,
			img {
				border-radius: 0;
			}
		}
	}
}
