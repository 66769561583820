/* Match Preview */

.match-preview {

}
			.match-preview__date {
				display: block;
				text-transform: none;
				font-family: $font-family-base;
				font-weight: 400;
			}

				.match-preview__conj {
					font-style: italic;

					@media (min-width: 768px) {
						font-size: 36px;
					}

					@media (min-width: 992px) and (max-width: 1199px){
						font-size: 26px;
					}
				}
					.match-preview__team-name {
						font-style: normal;

						@media (min-width: 768px) {
							font-size: 14px;
						}
					}

					.match-preview__team-info {
						font-family: $font-family-base;
						text-transform: none;
						font-weight: 400;
						font-size: 9px;

						@media (min-width: 768px) {
							font-size: 10px;
						}
					}
						.match-preview__match-time {
							font-weight: 400;
						}
						.match-preview__match-place {
							font-weight: 400;
						}

						.match-preview__match-info--header {

							.match-preview__match-place {
								color: $body-font-color;
							}
						}
