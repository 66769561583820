/* Widget: Lists */
.widget_archive,
.widget_nav_menu,
.widget_meta,
.widget_pages,
.widget_recent_comments,
.widget_recent_entries,
.widget_categories,
.widget_rss,
.widget_product_categories,
.widget_layered_nav,
.widget_layered_nav_filter {


}

.widget--footer {

	.widget__list {
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: 13px;
		font-weight: 400;
		text-transform: none;
		line-height: 1.5em;

		li {
			padding-bottom: 8px;
		}

		> li {

			& + li {
				border-top: none;
			}

			> a {
				display: inline;
				padding: 0;
				color: $body-font-color;

				&:hover {
					color: $color-white;
				}
			}

			&.has-children {
				background-image: $select-caret;
				background-position: right $card-horizontal-padding top $card-vertical-padding;
				background-repeat: no-repeat;
				background-size: 6px 4px;
			}
		}

		ul {
			border-top: 1px solid $card-border-color;
			list-style: none;
			padding: ($card-vertical-padding - 6) $card-horizontal-padding;

			@if $template == football {
				background-color: $color-dark-2;
				font-size: 10px;
			}

			li {
				padding: 10px 0;

				a {
					color: $body-font-color;
					transition: color 0.3s ease;

					&:hover {
						color: $color-2;
					}
				}
			}
		}
	}
}
