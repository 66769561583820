/* Event: Lineup */
.alc-event-lineup {

}


// Footer
.alc-event-lineup__footer {
	padding: 10px 15px;
	background-color: $card-subheader-bg;
	border-top: 1px solid $card-border-color;

	@media (min-width: 992px) {
		padding-left: $card-vertical-padding;
		padding-right: $card-vertical-padding;
	}
}

// Team Bans
.alc-event-lineup__bans {
	display: flex;
	justify-content: space-between;

	@media (max-width: 575px) {
		flex-direction: column;
	}
}
	.alc-event-lineup__ban {
		display: flex;
		align-items: center;

		@media (max-width: 575px) {
			flex-direction: column;

			.alc-event-lineup__ban-title {
				margin-bottom: .5em;
			}
		}
	}
	.alc-event-lineup__ban--second {

		.alc-event-lineup__ban-title {

			@media (min-width: 576px) {
				order: 2;
			}
		}

		@media (max-width: 575px) {
			margin-top: 14px;
		}
	}
		.alc-event-lineup__ban-title {
			font-size: 11px;
			line-height: 1.2em;
			margin-bottom: 0;
		}
		.alc-event-lineup__ban-items {
			display: flex;
			padding: 0 8px;
		}
			.alc-event-lineup__ban-item {
				width: 20px;
				height: 20px;
				overflow: hidden;
				border-radius: 50%;
				margin: 0 6px;

				img {
					vertical-align: top;
				}
			}

// Styles

// Style 1
.alc-event-lineup--style1 {

	.alc-event-lineup__header {
		display: flex;
		justify-content: space-between;
		padding: 15px;

		@media (min-width: 992px) {
			padding-left: $card-vertical-padding;
			padding-right: $card-vertical-padding;
		}
	}
		.alc-event-lineup__team {
			display: flex;
			align-items: center;
		}
		.alc-event-lineup__team--first {

		}
		.alc-event-lineup__team--second {

			.alc-event-lineup__team-logo {
				margin-right: 0;
				margin-left: 8px;
				order: 2;

				@media (min-width: 992px) {
					margin-left: 14px;
					margin-right: 0;
				}
			}
			.alc-event-lineup__team-meta {
				text-align: right;
			}
		}

			.alc-event-lineup__team-logo {
				margin-right: 8px;

				@media (min-width: 992px) {
					margin-right: 14px;
				}
			}

			.alc-event-lineup__team-meta {

			}
				.alc-event-lineup__team-name {
					font-size: 12px;
					line-height: 1.2em;
					margin-bottom: 0;
				}
				.alc-event-lineup__team-desc {
					display: block;
					font-size: 9px;
					line-height: 1.2em;
				}
}


// Event Lineup Tables
.alc-event-lineup__stats {
	margin-bottom: 0;
}

.alc-event-lineup__stats--style1 {

	@media (max-width: 767px) {
		width: 600px;
		max-width: none;
	}

	.alc-event-lineup-stat__player-cell {

		&.text-right {

			.alc-event-lineup-stat__player-inner {
				order: 2;
			}
				.alc-event-lineup-stat__thumb {
					order: 2;
				}
				.alc-event-lineup-stat__thumb--hexagon {
					margin-right: 0;
				}
				.alc-event-lineup-stat__thumb--hexagon:first-child {
					margin-left: 8px;
				}
				.alc-event-lineup-stat__thumb--hexagon + .alc-event-lineup-stat__thumb--hexagon {
					margin: 0 0 0 8px;
				}
				.alc-event-lineup-stat__items {
					order: 1;
				}
		}
	}
		.alc-event-lineup-stat__player {
			display: flex;
			align-items: center;
		}
			.alc-event-lineup-stat__player-inner {
				display: flex;
			}
				// Thumbnail
				.alc-event-lineup-stat__thumb {
					position: relative;

					img {
						border: 2px solid #189aff;
						border-radius: 3px;
					}
				}
					//
					.alc-event-lineup-stat__badge {
						display: block;
						position: absolute;
						right: 0;
						bottom: 0;
						background-color: #189aff;
						padding: 2px 3px;
						font-size: 8px;
						line-height: 1.2em;
						font-family: $font-family-accent;
						font-weight: 700;
						color: $color-white;
						text-align: center;
						min-width: 12px;
					}

				// Colors

				// Color: Alt
				.alc-event-lineup-stat__thumb--color-alt {

					img {
						border-color: #f21956;
					}

					.alc-event-lineup-stat__badge {
						background-color: #f21956;
					}

					&.alc-event-lineup-stat__thumb--hexagon {
						background-color: #f21956;
					}
				}

				// Color: Primary
				.alc-event-lineup-stat__thumb--color-primary {

					img {
						border-color: $color-primary;
					}

					.alc-event-lineup-stat__badge {
						background-color: $color-primary;
					}

					&.alc-event-lineup-stat__thumb--hexagon {
						background-color: $color-primary;
					}
				}

				// Color: Info
				.alc-event-lineup-stat__thumb--color-info {

					img {
						border-color: $color-info;
					}

					.alc-event-lineup-stat__badge {
						background-color: $color-info;
					}

					&.alc-event-lineup-stat__thumb--hexagon {
						background-color: $color-info;
					}
				}

				// Hexagon
				.alc-event-lineup-stat__thumb--hexagon {
					overflow: hidden;
					clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
					background-color: #189aff;
					padding: 2px;
					margin-right: 8px;

					img {
						border: none;
						clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
					}
				}

				.alc-event-lineup-stat__items {
					display: flex;
					flex-direction: column;
					margin: 0 4px;
				}
					.alc-event-lineup-stat__item {
						border-radius: 3px;
					}
					.alc-event-lineup-stat__item + .alc-event-lineup-stat__item {
						margin-top: 4px;
					}

			.alc-event-lineup-stat__meta {
				flex: 1;
				padding: 0 6px;
			}
				.alc-event-lineup-stat__nickname {
					font-size: 12px;
					line-height: 1.2em;
					margin-bottom: .085em;
				}
				.alc-event-lineup-stat__desc {
					font-size: 9px;
					line-height: 1.2em;
					text-transform: uppercase;
					font-family: $font-family-base;
				}
				.alc-event-lineup-stat__desc--sm {
					text-transform: none;
				}
					.alc-event-lineup-stat__desc-item + .alc-event-lineup-stat__desc-item {

						&::before {
							content: "|";
							display: inline-block;
							padding: 0 3px 0 2px;
						}
					}

	// Numbers
	.alc-event-lineup-stat__numbers {

	}
		.alc-event-lineup-stat__numbers-list {
			margin: 0;
		}
			.alc-event-lineup-stat__number-item {
				display: inline-block;
			}
			.alc-event-lineup-stat__number-item + .alc-event-lineup-stat__number-item {

				&::before {
					content: "/";
					display: inline-block;
					color: $color-white;
					padding-right: .2em;
				}
			}

	// Info Items
	.alc-event-lineup-stat__info {

		&.text-right .alc-event-lineup-stat__info-list {
			margin-left: auto;
		}
	}
		.alc-event-lineup-stat__info-list {
			display: flex;
			flex-wrap: wrap;
			margin: 0;
			padding: 0;
			max-width: 80px;
			justify-content: center;
		}
			.alc-event-lineup-stat__info-item {
				margin: 2px;

				img {
					border-radius: 3px;

					&:hover {
						cursor: pointer;
					}
				}
			}

	// Role
	.alc-event-lineup-stat__role {
		color: $headings-color;
		font-weight: bold;
	}
}

.alc-event-lineup-tooltip {

}
	.alc-event-lineup-tooltip__header {
		display: flex;
		align-items: center;
		margin-bottom: 14px;
		text-align: left;

		img {
			margin-right: 8px;
			border-radius: 3px;
		}

		h6 {
			font-size: 12px;
			line-height: 1.2em;
			margin-bottom: 0;
			flex: 1;
			text-transform: uppercase;
			font-style: italic;
		}
	}
	.alc-event-lineup-tooltip__desc {
		font-size: 10px;
		line-height: 1.6em;
		text-align: left;
		font-family: $font-family-base;
		font-weight: 400;
		color: $body-font-color;
		text-transform: none;
	}
